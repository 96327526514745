<template>
	<v-row justify="center">
		<v-dialog :value="dialog" max-width="650" @input="closeDialog">
			<v-card class="dialog-card">
				<v-card-title class="headline accent--text pb-4">
          {{ isEdit ? 'Редактирование раздела расписания' : 'Добавление раздела расписания' }}
        </v-card-title>

				<v-card-text outlined tile class="border-top">
					<v-container>
						<v-row>
							<v-col cols="12" class="pb-0 pt-5 px-0">
                <v-textarea
                  v-model="chapterLocal.name"
                  label="Название"
                  no-resize
                  auto-grow
                  rows="1"
                  :error-messages="errorMessages.name"
                  @input="$v.chapterLocal.name.$touch()"
                  @blur="$v.chapterLocal.name.$touch()"
                />
              </v-col>
            </v-row>
					</v-container>
				</v-card-text>				

        <v-card-actions class="px-6">
          <v-spacer></v-spacer>
          <v-btn text color="accent" @click="closeDialog">Отмена</v-btn>

          <v-btn
            :disabled="$v.$invalid"
						:loading="loading"
            color="success"
            text
            @click="saveChapter"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import { required, maxLength } from 'vuelidate/lib/validators'
import { regexFrom0To99, setValidateMessages } from '@/scripts/validation'
import { Chapter } from '@/models'

export default {
  name: 'ChapterDialog',

	props: {
		dialog: Boolean,
    chapter: Object,
		loading: Boolean
	},

	data: () => ({
    chapterLocal: new Chapter()
	}),

  validations() {
    return {
      chapterLocal: {
        name: { required },
      }
    }
  },

	computed: {
    isEdit() {
      return this.chapterLocal?.id
    },

    errorMessages() {
      const allErrors = {}

      Object.keys(this.$v.chapterLocal.$params).forEach(key => {
        allErrors[key] = setValidateMessages(this.$v.chapterLocal[key])
      })

      return allErrors
    }
	},

	methods: {
		saveChapter() {
      this.$emit('saveChapter', _cloneDeep(this.chapterLocal))
    },

    resetModal() {
      this.$v.$reset()
      this.chapterLocal = new Chapter()
    },

    closeDialog() {
      this.resetModal()
			this.$emit('closeDialog')
		}
	},

  watch: {
    chapter(val) {
      this.chapterLocal = val ? Chapter.buildFromAPI(val) : new Chapter()
    },

    dialog(val) {

      !val && this.resetModal()
    }
  }
}
</script>