<template>
  <div v-show="dataLoaded">
    <v-row>
      <v-col
        cols="12"
        class="pt-0 mt-3"
      >
        <v-btn
          small
          color="primary"
          class="white--text px-4"
          @click="dialog = true"
        >
          <v-icon
            left
            small
          >mdi-plus
          </v-icon>
          Добавить
        </v-btn>

        <v-card
          class="table-card mt-4"
        >

          <v-card-title>
            <span class="accent--text">Разделы</span>
            <v-spacer></v-spacer>

            <v-text-field
              v-model="tableSearch"
              class="mt-0 pt-0 table-search"
              append-icon="mdi-magnify"
              label="Поиск"
              single-line
              hide-details
              clearable
            />
          </v-card-title>

          <v-data-table
            ref="eventPlacesTable"
            class="base-table"
            fixed-header
            loading-text="Загрузка данных"
            :loading="tableLoading"
            hide-default-footer
            :headers="headers"
            :items="chapterList.list"
            :items-per-page="-1"
            :search="tableSearch"
          >

            <template #item.index="{ index }">
              {{ index + 1 }}
            </template>

            <template #item.actions="{ item }">
              <v-icon
                class="mr-1 edit-btn"
                color="btnIcon"
                size="22"
                title="Редактировать"
                @click="editChapter(item)"
              >
                mdi-pencil-outline
              </v-icon>

              <v-icon
                class="remove-btn"
                color="btnIcon"
                size="22"
                title="Удалить"
                @click="confirmRemoveChapter(item)"
              >
                mdi-trash-can-outline
              </v-icon>
            </template>

            <template slot="no-data">
              <div>Разделы расписания не найдены</div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <ChapterDialog
      :dialog="dialog"
      :chapter="selectedItem"
      :loading="chapterLoading"
      @saveChapter="saveChapter"
      @closeDialog="closeDialog"
    />

    <DeleteConfirmDialog
      :dialog="confirmDialog"
      :loading="deleteLoading"
      :message="'Вы уверены, что хотите удалить раздел расписания?'"
      @confirm="removeChapter"
      @closeConfirmModal="closeConfirmDialog"
    />
  </div>
</template>

<script>
import { catalogApi, chapterApi, eventPlaceApi } from '@/api'
import { mapGetters } from 'vuex'
import { AcademicWork, ChapterList, EventPlace } from '@/models'
import ChapterDialog from '@/components/department/chapters/dialogs/Chapter'
import DeleteConfirmDialog from '@/components/dialogs/DeleteConfirm'
import _debounce from 'lodash/debounce'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'ChapterTable',

  components: {
    ChapterDialog,
    DeleteConfirmDialog
  },

  created() {
    this.getChapters()
  },

  mounted() {
    this.setTableHeight()
    window.addEventListener('resize', this.setTableHeight)
  },

  destroyed() {
    window.removeEventListener('resize', this.setTableHeight)
  },

  data: () => ({
    dataLoaded: false,
    tableSearch: '',
    headers: [
      { text: '№', value: 'index', sortable: false, align: 'left' },
      { text: 'Название', value: 'name', sortable: false, align: 'left' },
      { text: 'Действия', value: 'actions', sortable: false, align: 'right', width: '100px' }
    ],
    selectedItem: null,
    dialog: false,
    confirmDialog: false,
    chapterLoading: false,
    deleteLoading: false,
    pageNumber: 0,
    lastPage: false,
    total: 0,
    tableLoading: false,

    chapterList: new ChapterList()
  }),

  computed: {
    ...mapGetters('system', ['techMessageHeight']),
    ...mapGetters('department', ['departmentId', 'department']),
  },

  methods: {
    async getChapters() {
      try {
        const chapters = await chapterApi.findAll(this.departmentId)

        const sortChapters = chapters.map(el => {
          el.name?.toUpperCase();
          return el
        }).sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        })

        this.chapterList.buildFromAPI(sortChapters)

      } catch (e) {
        console.log(e.status)
      } finally {
        this.dataLoaded = true
        this.dataLoaded = true
        this.$emit('dataLoaded')
      }
    },

    async saveChapter(chapter) {
      try {
        this.chapterLoading = true

        const existingChapter = this.chapterList.list.find(el => el.name === chapter.name.trim())
        if (existingChapter) {
          this.$toastr('warning', '', 'Раздел расписания с таким именем уже существует')
          return
        }

        const chapterForApi = chapter.getForAPI()
        if (!chapterForApi.department?.id) chapterForApi.department = this.department

        const data = chapter.id
          ? await chapterApi.update(chapterForApi)
          : await chapterApi.add(chapterForApi)

        chapter.id ? this.chapterList.updateChapter(data) : this.chapterList.addChapter(data)
        this.closeDialog()
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка сохранения раздела расписания')
      } finally {
        this.chapterLoading = false
      }
    },

    async removeChapter() {
      try {
        if (this.selectedItem.id) {
          this.deleteLoading = true

          await chapterApi.remove(this.selectedItem.id)
          this.chapterList.removeChapter(this.selectedItem.id)
          this.closeConfirmDialog()
        }
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка удаления раздела расписания')
      } finally {
        this.deleteLoading = false
      }
    },

    setTableHeight() {
      const otherElements = 355 + this.techMessageHeight

      if (this.$refs?.eventPlacesTable) {
        this.$refs.eventPlacesTable.$el.style.maxHeight = window.innerHeight - otherElements + 'px'
      }
    },

    editChapter(item) {
      this.selectedItem = item
      this.dialog = true
    },

    confirmRemoveChapter(item) {
      this.selectedItem = item
      this.confirmDialog = true
    },

    closeDialog() {
      this.dialog = false
      this.selectedItem = null
    },

    closeConfirmDialog() {
      this.confirmDialog = false
      this.selectedItem = null
    }
  }
}
</script>